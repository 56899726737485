<template>
	<LayoutMain>
		<LayoutHeaderSection>
			<template #title>
				<LayoutTextSection :text="(route.params.id) ? 'Editar publicación' : 'Crear publicación'" :breadcrumb="true" />
			</template>
		</LayoutHeaderSection>
		<main class="container-md pt-8 vstack gap-10">
			<CommonFormWrapper :submitText="(route.params.id) ? 'Guardar' : 'Crear'" :cancelUrl="{ name: 'account.publication.index' }" :submitting="submitting" :errors="validationExternal" @submit="submit" v-if="!loading">
				<template #body>
					<CommonFormInputSelect v-model="publication.type" title="Tipo" placeholder="Seleccionar el tipo" :options="$env.FIELDS.types" optionsType="advance" :validation="validation.currency" />
					<CommonFormInputSelect v-model="publication.category" title="Categoría" placeholder="Seleccionar la categoría" :options="categories" optionsType="advance" :validation="validation.category" />
					<CommonFormInputText v-model="publication.title" title="Título" placeholder="Ingrese un título" :validation="validation.title" />
					<CommonFormInputTextarea v-model="publication.description" title="Descripción" subtitle="Opcional" placeholder="Ingrese una descripción" />
					<CommonFormInputText v-model="publication.url" title="Link" subtitle="Opcional" placeholder="Ingrese una url" :validation="validation.url" />
					<CommonFormInputSelect v-model="publication.currency" title="Moneda" placeholder="Seleccionar moneda" :options="$env.FIELDS.currencies" :validation="validation.currency" />
					<div class="hstack gap-5 align-items-start">
						<CommonFormInputAmount v-model="publication.amount" title="Monto" placeholder="Ingrese un valor" :validation="validation.amount" class="flex-fill" />
						<CommonFormInputAmount v-model="publication.amount_sale" title="Oferta" subtitle="Opcional" placeholder="Ingrese un valor" :validation="validation.amount_sale" class="flex-fill" />
					</div>
					<AccountPublicationFormProperty v-model:publication="publication" :validation="validation" v-if="publication.type == 'property'" />
					<AccountPublicationFormClothing v-model:publication="publication" :validation="validation" v-if="publication.type == 'clothing'" />
					<CommonFormInputImages v-model="publication.medias" title="Subir imagenes" :submitting="submitting" />
					<CommonFormInputBoolean v-model="publication.public" title="Estado de la publicación" :options="{ true: { title: 'Pública', description: 'Otros usuarios podrán verla' }, false: { title: 'Privada', description: 'Sólo se mostrarán en ciertos grupos' }}" :validation="validation.public" />
				</template>
			</CommonFormWrapper>
		</main>
	</LayoutMain>
</template>

<script>
	import { reactive, computed, watch, onBeforeMount, inject } from 'vue';
	import router from '@/router';
	import { useRoute } from 'vue-router';
	import _ from 'lodash';
	import useVuelidate from '@vuelidate/core';
	import { required } from '@/helpers/i18n/validators';
	import composableForm from '@/composables/form';
	import AccountPublicationRepository from '@/repositories/Account/PublicationRepository';

	export default {
		setup() {
			const { loading, submitting, validationExternal, catchError } = composableForm();
			const $env = inject('$env');
			const route = useRoute();

			const categories = computed(() => {
				return _.pickBy($env.FIELDS.categories, (category) => category.types.includes(publication.type));
			});

			const publication = reactive({
				reference: null,
				type: null,
				category: null,
				operation: null,
				url: null,
				title: null,
				description: null,
				currency: null,
				amount: null,
				amount_sale: null,
				extras: {
					brand: null,
					code: null,
					tags: [],
					last_season: null,
					gender: null,
					age_group: null,
					age: null,
					areas: {
						total: null,
						covered: null
					},
					rooms: {
						total: null,
						bedrooms: null,
						bathrooms: null
					},
					parkings: null,
					credit: null,
					reserved: null
				},
				agents: [],
				public: (route.query?.public === 'false') ? false : true,
				address: {},
				medias: []
			});

			const validation = useVuelidate({}, publication);

			const submit = async () => {
				if(submitting.value) return;
				validationExternal.value = [];
				if(!await validation.value.$validate()) return;

				submitting.value = true;

				if(route.params.id != undefined) {
					await AccountPublicationRepository.update(route.params.id, publication).then((response) => {
						router.push({ name: `account.publication.index` });
					}).catch(catchError);
				}else{
					await AccountPublicationRepository.store(publication).then((response) => {
						router.push({ name: `account.publication.index` });
					}).catch(catchError);
				}

				submitting.value = false;
			}

			onBeforeMount(async () => {
				if(route.params.id != undefined) {
					await AccountPublicationRepository.show(route.params.id).then((response) => {
						if(response.data.medias.length > 0) response.data.medias = _.map(response.data.medias, (media, index) => {
							return { ...media, number: index };
						});

						_.assign(publication, response.data);
					}).catch((e) => {
						catchError(e, true);
						router.push({ name: `account.publication.index` });
					});
				}

				loading.value = false;
			});

			watch(() => publication.type, (newType) => {
				if(publication.category && !$env.FIELDS.categories[publication.category].types.includes(newType))
					publication.category = null;
			});

			return { route, loading, publication, submit, submitting, validation, validationExternal, categories };
		}
	}
</script>