<template>
	<transition-group name="fade-form-fast">
		<SearchFilterType v-model="form.type" @update:modelValue="change('type', false);" :step="step" @setStep="setStep" v-if="steps.includes('type')" />
		<SearchFilterCategory v-model="form.category" @update:modelValue="change('category', false);" :step="step" :form="form" :filters="filters" @setStep="setStep" v-if="steps.includes('category')" />
		<SearchFilterOperation v-model="form.operation" @update:modelValue="change('operation', false);" :step="step" @setStep="setStep" v-if="steps.includes('operation')" />
		<SearchFilterAmount v-model:currency="form.currency" v-model:min="form.amount.min" v-model:max="form.amount.max" :step="step" :filters="filters" @setStep="setStep" v-if="steps.includes('amount')" />
		<SearchFilterExtrasTags v-model="form.extras.tags" @update:modelValue="change('extras.tags', true);" :step="step" :form="form" :filters="filters" @setStep="setStep" v-if="steps.includes('extras.tags')" />
		<SearchFilterExtrasBrands v-model="form.extras.brands" @update:modelValue="change('extras.brands', true);" :step="step" :form="form" :filters="filters" @setStep="setStep" v-if="steps.includes('extras.brands')" />
		<SearchFilterExtrasLastSeason v-model="form.extras.last_season" @update:modelValue="change('extras.last_season', false);" :step="step" @setStep="setStep" v-if="steps.includes('extras.last_season')" />
		<SearchFilterExtrasGender v-model="form.extras.gender" @update:modelValue="change('extras.gender', false);" :step="step" @setStep="setStep" v-if="steps.includes('extras.gender')" />
		<SearchFilterExtrasStockSizes v-model="form.extras.stock.sizes" @update:modelValue="change('extras.stock.sizes', true);" :step="step" :form="form" :filters="filters" @setStep="setStep" v-if="steps.includes('extras.stock.sizes')" />
		<SearchFilterExtrasStockPlaces v-model="form.extras.stock.places" @update:modelValue="change('extras.stock.places', true);" :step="step" :form="form" :filters="filters" @setStep="setStep" v-if="steps.includes('extras.stock.places')" />
		<SearchFilterExtrasAreasTotal v-model:min="form.extras.areas.total.min" v-model:max="form.extras.areas.total.max" :step="step" @setStep="setStep" v-if="steps.includes('extras.areas.total')" />
		<SearchFilterExtrasAreasCovered v-model:min="form.extras.areas.covered.min" v-model:max="form.extras.areas.covered.max" :step="step" @setStep="setStep" v-if="steps.includes('extras.areas.covered')" />
		<SearchFilterExtrasRoomsTotal v-model="form.extras.rooms.total" @update:modelValue="change('extras.rooms.total', false);" :step="step" @setStep="setStep" v-if="steps.includes('extras.rooms.total')" />
		<SearchFilterExtrasRoomsBedrooms v-model="form.extras.rooms.bedrooms" @update:modelValue="change('extras.rooms.bedrooms', false);" :step="step" @setStep="setStep" v-if="steps.includes('extras.rooms.bedrooms')" />
		<SearchFilterExtrasRoomsBathrooms v-model="form.extras.rooms.bathrooms" @update:modelValue="change('extras.rooms.bathrooms', false);" :step="step" @setStep="setStep" v-if="steps.includes('extras.rooms.bathrooms')" />
		<SearchFilterExtrasParkings v-model="form.extras.parkings" @update:modelValue="change('extras.parkings', false);" :step="step" @setStep="setStep" v-if="steps.includes('extras.parkings')" />
		<SearchFilterExtrasReserved v-model="form.extras.reserved" @update:modelValue="change('extras.reserved', false);" :step="step" @setStep="setStep" v-if="steps.includes('extras.reserved')" />
	</transition-group>
</template>

<script>
	import { ref, computed, onMounted, inject } from 'vue';
	import VueScrollTo from 'vue-scrollto';
	import _ from 'lodash';

	export default {
		props: {
			filters: Object
		},
		setup(props) {
			const $env = inject('$env');
			const formDefault = {
				title: null,
				type: null,
				category: null,
				operation: null,
				currency: ((props.filters?.currencies ?? []).length > 0) ? props.filters.currencies[0] : null,
				amount: {
					min: null,
					max: null
				},
				extras: {
					tags: [],
					brands: [],
					last_season: null,
					gender: null,
					stock: {
						sizes: [],
						places: []
					},
					areas: {
						total: {
							min: null,
							max: null
						},
						covered: {
							min: null,
							max: null
						}
					},
					rooms: {
						total: null,
						bathrooms: null,
						bedrooms: null
					},
					parkings: null,
					credit: null,
					reserved: null
				}
			};
			const form = ref(_.cloneDeep(formDefault));

			const steps = computed(() => {
				let data = [];

				if(Object.keys(props.filters) == 0 || (props.filters?.types ?? []).length > 1) data.push('type');
				if(form.value.type) {
					let typeInfo = $env.FIELDS.types[form.value.type],
						categoryInfo = $env.FIELDS.categories[form.value.category] ?? {};

					if(typeInfo.filters.includes('categories') && (Object.keys(props.filters) == 0 || Object.keys(props.filters?.categories ?? {}).length > 1)) data.push('category');
					if(typeInfo.filters.includes('operations')) data.push('operation');
					data.push('amount');

					if((categoryInfo.filters ?? []).includes('extras.areas.total')) data.push('extras.areas.total');
					if((categoryInfo.filters ?? []).includes('extras.areas.covered')) data.push('extras.areas.covered');
					if((categoryInfo.filters ?? []).includes('extras.rooms.*')) data.push('extras.rooms.total');
					if((categoryInfo.filters ?? []).includes('extras.rooms.*')) data.push('extras.rooms.bedrooms');
					if((categoryInfo.filters ?? []).includes('extras.rooms.*')) data.push('extras.rooms.bathrooms');
					if((categoryInfo.filters ?? []).includes('extras.parkings')) data.push('extras.parkings');
					if(typeInfo.filters.includes('extras.credit')) data.push('extras.credit');
					if(typeInfo.filters.includes('extras.reserved')) data.push('extras.reserved');

					if(form.value?.category in (props.filters?.categories ?? {})) {
						if(props.filters.categories[form.value.category]?.tags.length > 1) data.push('extras.tags');
						if(props.filters.categories[form.value.category]?.brands.length > 1) data.push('extras.brands');
						if(props.filters.categories[form.value.category]?.last_season.length > 1) data.push('extras.last_season');
						if(props.filters.categories[form.value.category]?.genders.length > 1) data.push('extras.gender');
						if(props.filters.categories[form.value.category]?.sizes.length > 1) data.push('extras.stock.sizes');
						if(props.filters.categories[form.value.category]?.places.length > 1) data.push('extras.stock.places');
					}
				}

				return data;
			});

			const step = ref(null);
			const stepTimeout = ref(null);

			const setStep = (name) => {
				step.value = name;
				setTimeout(() => {
					let element = document.getElementById(`step-${step.value}`);
					if(element) VueScrollTo.scrollTo(element, 0, { offset: () => { return -10; }});
				}, 300);
			}

			const setStepNext = (delay = true) => {
				let indexNext = _.indexOf(steps.value, step.value) + 1,
					stepNext = _.nth(steps.value, indexNext);

				if(stepNext) {
					if(stepTimeout.value) clearTimeout(stepTimeout.value);
					stepTimeout.value = setTimeout(() => setStep(stepNext), (step.value && delay) ? 1500 : 0);
				}
			}

			const changeLast = ref(null);
			const change = (field, delay) => {
				if(['type', 'category'].includes(field)) {
					let formNew = _.cloneDeep(formDefault);
					formNew.type = form.value.type;
					if(field == 'category') formNew.category = form.value.category;
					_.assign(form.value, formNew);
				}

				if(changeLast.value != field) {
					changeLast.value = field;
					setStepNext(delay);
				}else if(stepTimeout.value) clearTimeout(stepTimeout.value);
			}

			const init = () => {
				if(props.filters && (props.filters?.types ?? []).length == 1) form.value.type = _.head(props.filters.types);
				setStepNext(false);
			}

			const getData = () => {
				let data = _.cloneDeep(form.value);
				data.title = getTitle();

				return data;
			}

			const empty = () => {
				_.assign(form.value, _.cloneDeep(formDefault));
				step.value = null;
				init();
			}

			const completed = computed(() => {
				if(steps.value.includes('type') && !form.value.type) return false;
				if(steps.value.includes('category') && !form.value.category) return false;
				return true;
			});

			const getTitle = () => {
				let title = [];
				if(steps.value.includes('type') && form.value.type) title.push($env.FIELDS.types[form.value.type].description);
				if(steps.value.includes('operation') && form.value.operation) title.push($env.FIELDS.operations[form.value.operation].description);
				if(steps.value.includes('category') && form.value.category) title.push($env.FIELDS.categories[form.value.category].description);

				if(steps.value.includes('amount') && (form.value.amount.min || form.value.amount.max)) {
					let numberFormat = new Intl.NumberFormat('es-ES');

					if(form.value.amount.max > 0) title.push(`hasta ${numberFormat.format(form.value.amount.max)}${(form.value.currency) ? ' '+form.value.currency : ''}`);
					else if(form.value.amount.min > 0) title.push(`desde ${numberFormat.format(form.value.amount.min)}${(form.value.currency) ? ' '+form.value.currency : ''}`);
				}

				if(steps.value.includes('extras.brands') && form.value.extras.brands.length > 0) {
					let sizes = _.sortBy(_.cloneDeep(form.value.extras.brands));
					title.push(sizes.join(', '));
				}
				if(steps.value.includes('extras.last_season') && form.value.extras.last_season) title.push($env.FIELDS.last_season[form.value.extras.last_season].description);
				if(steps.value.includes('extras.gender') && form.value.extras.gender) title.push($env.FIELDS.genders[form.value.extras.gender].description);
				if(steps.value.includes('extras.stock.sizes') && form.value.extras.stock.sizes.length > 0) {
					let sizes = _.sortBy(_.cloneDeep(form.value.extras.stock.sizes));
					title.push(`talles ${sizes.join(', ')}`);
				}
				if(steps.value.includes('extras.stock.places') && form.value.extras.stock.places.length > 0) {
					let places = _.sortBy(_.cloneDeep(form.value.extras.stock.places));
					title.push(`en ${places.join(', ')}`);
				}

				if(steps.value.includes('extras.rooms.total') && form.value.extras.rooms.total == 1) title.push(`1 ambiente`);
				else if(steps.value.includes('extras.rooms.total') && form.value.extras.rooms.total > 1) title.push(`desde ${form.value.extras.rooms.total} ambientes`);
				else if(steps.value.includes('extras.rooms.bedrooms') && form.value.extras.rooms.bedrooms == 1) title.push(`1 dormitorio`);
				else if(steps.value.includes('extras.rooms.bedrooms') && form.value.extras.rooms.bedrooms > 1) title.push(`desde ${form.value.extras.rooms.bedrooms} dormitorios`);

				if(steps.value.includes('extras.areas.total') && form.value.extras.areas.total.max) title.push(`hasta ${form.value.extras.areas.total.max} m2 totales`);
				else if(steps.value.includes('extras.areas.total') && form.value.extras.areas.total.min) title.push(`desde ${form.value.extras.areas.total.min} m2 totales`);
				else if(steps.value.includes('extras.areas.covered') && form.value.extras.areas.covered.max) title.push(`hasta ${form.value.extras.areas.covered.max} m2 cubiertos`);
				else if(steps.value.includes('extras.areas.covered') && form.value.extras.areas.covered.min) title.push(`desde ${form.value.extras.areas.covered.min} m2 cubiertos`);

				if(steps.value.includes('extras.credit') && form.value.extras.credit) title.push(`apto crédito`);

				return title.join(' ');
			}

			onMounted(init);

			return { form, steps, step, setStep, change, getData, empty, completed };
		}
	}
</script>