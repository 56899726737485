import Repository from './Repository';
import { useToast } from 'vue-toastification';
import _ from 'lodash';

const resource = '/auth';
const toast = useToast();

export default {
	check(payload) {
		return Repository.post(`${resource}/check`, payload);
	},
	signup(payload) {
		return Repository.post(`${resource}/signup`, payload);
	},
	login(payload) {
		return Repository.post(`${resource}/login`, payload);
	},
	loginOtp(payload) {
		return Repository.post(`${resource}/login/otp`, payload);
	},
	loginSocial(provider, data) {
		return Repository.post(`${resource}/login/social/${provider}`, data);
	},
	logout() {
		return Repository.get(`${resource}/logout`);
	},
	user() {
		return Repository.get(`${resource}/user`);
	},
	update(payload) {
		return Repository.post(`${resource}/user`, _.set(payload, '_method', 'put'), { headers: { 'Content-Type': 'multipart/form-data' }});
	},
	accounts() {
		return Repository.get(`${resource}/user/accounts`);
	},
	updateSlug(payload) {
		return Repository.post(`${resource}/user/slug`, _.set(payload, '_method', 'put'));
	},
	notificationTypes(payload) {
		return Repository.post(`${resource}/user/notificationTypes`, _.set(payload, '_method', 'put'));
	},
	recoverSend(payload) {
		return Repository.post(`${resource}/recover/send`, payload).then((response) => {
			toast.success(`Código enviado.`);
			return response;
		});
	},
	recoverCheck(payload) {
		return Repository.post(`${resource}/recover/check`, payload).then((response) => {
			toast.success(`Verificado correctamente.`);
			return response;
		});
	},
	verifySend(payload) {
		return Repository.post(`${resource}/verify/send`, payload).then((response) => {
			toast.success(`Código enviado.`);
			return response;
		});
	},
	verifyCheck(payload) {
		return Repository.post(`${resource}/verify/check`, payload).then((response) => {
			toast.success(`Verificado correctamente.`);
			return response;
		});
	}
}