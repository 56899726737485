import store from '@/store';
import _ from 'lodash';
import composableForm from '@/composables/form';
import ConfigurationRepository from '@/repositories/ConfigurationRepository';

const setConfiguration = (app, config) => {
	app.config.globalProperties.$env = config;
	app.provide('$env', config);
}

export const loadConfiguration = async (app) => {
	const { catchError } = composableForm();
	const info = store.getters['config/info'];

	if(info) setConfiguration(app, info);
	else await ConfigurationRepository.index().then((response) => {
		store.dispatch('config/setInfo', response.data);
		setConfiguration(app, response.data);
	}).catch(catchError);
};

const setManifest = (app, manifest) => {
	app.config.globalProperties.$manifest = manifest;
	app.provide('$manifest', manifest);
}

const setPageInfo = (manifest) => {
	let icon = _.find(manifest.icons, { sizes: '192x192' }),
		link = document.createElement('link');

	link.rel = 'icon';
	link.href = icon.src;
	document.head.appendChild(link);
	document.title = manifest.name;
}

export const loadManifest = async (app) => {
	let response = await fetch('/manifest.webmanifest'),
		data = await response.json();

	if(data) {
		setManifest(app, data);
		setPageInfo(data);
	}
};